import * as React from "react"
import SEO from "../components/seo"

const IndexPage = () => (
  <div>
    <SEO title="Generous AI" />
    <h1>Generous</h1>
  </div>
)

export default IndexPage
